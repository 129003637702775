import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <section className="px-6 py-12 section bg-white">
      <div className="container">
        <div className="mx-auto lg:w-2/3">
          <div className="section">
            <h2 className="mb-5 text-4xl font-bold">404 Fehler</h2>
            <div className="content">
              <h3>Seite nicht gefunden.</h3>
              <p>Hoppla! Diese Seite konnte nicht gefunden werden.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
